<template>
  <div class="admin-create">
    <h4 class="create-title">{{ $lang.app.reports }}</h4>
    <div class="admin-form-wrapper">
      <div class="admin-form">
        <Form @submit="submit" :validation-schema="schema">
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.report_type }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <i class="bi-chevron-down"></i>
              <Field as="select" name="reportType"
                     v-model="reportForm.reportType">
                <option value="" disabled>{{ $lang.app.select_from }}</option>
                <option v-for="reportType in reportTypes" :key="reportType.value"
                        :value="reportType.value">{{ reportType.title }}</option>
              </Field>
            </div>
            <ErrorMessage name="reportType" class="recovery-label-error" />
          </div>
          <div class="double-input" v-if="isRegionSelectAvailable()">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.region_city }}</label>
              <div class="reg-step-1-select-container">
                <i class="bi-chevron-down"></i>
                <Field as="select" name="regionId"
                       v-model="reportForm.regionId">
                  <option :key="null"
                          :value="null">Выбрать</option>
                  <option v-for="region in helper.regions" :key="region.id"
                          :value="region.id">{{ region.label }}</option>
                </Field>
              </div>
              <ErrorMessage name="regionId" class="recovery-label-error" />
            </div>
            <div v-if="helper.districts.length" class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.raion }}</label>
              <div class="reg-step-1-select-container">
                <i class="bi-chevron-down"></i>
                <Field as="select" name="districtId"
                       v-model="reportForm.districtId">
                  <option v-for="district in helper.districts" :key="district.id"
                          :value="district.id">{{ district.label }}</option>
                </Field>
              </div>
              <ErrorMessage name="districtId" class="recovery-label-error" />
            </div>
          </div>

          <div class="double-input">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.date_from }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="startDate" type="date"
                       v-model="reportForm.startDate"/>
              </div>
              <ErrorMessage name="startDate" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.date_to }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="endDate" type="date"
                       v-model="reportForm.endDate"/>
              </div>
              <ErrorMessage name="endDate" class="recovery-label-error" />
            </div>
          </div>
          <div class="reg-step-btn">
            <button type="submit">{{ $lang.app.download }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from "yup";

export default {
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    return {
      reportTypes: [
        {
          value: 'SCHOOL_BY_REGION',
          title: this.$lang.app.school_report,
        },
        {
          value: 'PRESCHOOL_BY_REGION',
          title: this.$lang.app.preschool_report,
        },
        {
          value: 'SCHOOL_BY_DISTRICT',
          title: this.$lang.app.school_report_by_district,
        },
        {
          value: 'PRESCHOOL_BY_DISTRICT',
          title: this.$lang.app.preschool_report_by_district,
        },
        {
          value: 'SCHOOL_STATISTICS',
          title: this.$lang.app.school_statistics_report,
        },
      ],
      reportForm: {
        reportType: null,
        startDate: null,
        endDate: null,
        regionId: null,
        districtId: null,
      },
      schema: yup.object({
        reportType: yup.mixed().required(),
        startDate: yup.mixed().required(),
        endDate: yup.mixed().required()
      }),
      organizationForm: {
        type: 'PRESCHOOL',
        regionId: null,
        districtId: null,
        aymakId: null,
        organizationId: null,
      },
      helper: {
        organizationTypes: [
          {
            label: this.$lang.app.preschool,
            value: 'PRESCHOOL'
          },
          {
            label: this.$lang.app.school,
            value: 'SCHOOL'
          }
        ],
        regions: [],
        districts: [],
        aymaks: [],
        organizations: [],
      },
    }
  },
  created() {
    this.fetchChild(4948);
  },
  watch: {
    'reportForm.type'() {
      this.reportForm.regionId = null;
      this.reportForm.districtId = null;
      this.helper.districts = [];
      this.result = null
    },

    'reportForm.regionId'(value) {
      this.reportForm.districtId = null;
      // this.organizationForm.aymakId = null;
      this.helper.districts = [];
      this.helper.aymaks = [];
      if(value) {
        this.fetchChild(value)
      }
    },
  },
  methods: {
    submit() {
      let url = null
      let type = null
      switch (this.reportForm.reportType) {
        case 'SCHOOL_BY_REGION':
          url = '/report/by-region';
          type = 'SCHOOL';
          break;
        case 'PRESCHOOL_BY_REGION':
          url = '/report/by-region';
          type = 'PRESCHOOL';
          break;
        case 'SCHOOL_BY_DISTRICT':
          url = '/report/by-district';
          type = 'SCHOOL';
          break;
        case 'PRESCHOOL_BY_DISTRICT':
          url = '/report/by-district';
          type = 'PRESCHOOL';
          break;
        case 'SCHOOL_STATISTICS':
          url = '/report/statistics-by-region';
          type = 'SCHOOL';
          break;
      }

      const downloadUrl = new URL(process.env.VUE_APP_API_BASE_URL + url)

      downloadUrl.searchParams.append("type", type)
      downloadUrl.searchParams.append("startDate", this.reportForm.startDate)
      downloadUrl.searchParams.append("endDate", this.reportForm.endDate)

      if (this.isRegionSelectAvailable()) {
        downloadUrl.searchParams.append("regionId", (this.reportForm.regionId != null ? this.reportForm.regionId : ''))
        downloadUrl.searchParams.append("districtId", (this.reportForm.districtId != null ? this.reportForm.districtId : ''))
      }

      window.location.href = downloadUrl.href
    },
    isRegionSelectAvailable(){
      switch (this.reportForm.reportType) {
        case 'SCHOOL_BY_DISTRICT':
        case 'PRESCHOOL_BY_DISTRICT':
        case 'SCHOOL_STATISTICS':
          return true;
        default:
          return false;
      }
    },
    fetchChild(id) {
      return this.$axios.get(`/address-service/children?parent=${id}`)
          .then(({data}) => {
            if (data.length > 0) {
              switch (data[0].type) {
                case 5:
                case 2:
                  this.helper.regions = this.getParsed(data)
                  break;
                case 3:
                case 6:
                  this.helper.districts = this.getParsed(data);
                  break;
                case 4:
                case 7:
                case 101:
                  this.helper.aymaks = this.getParsed(data)
                  break;
              }
            }
          })
    },
    getParsed(data) {
      return data.map(item => {
        return {
          id: item.id,
          label: this.$lang.$translate({kg: item.nameKg + ' ' + item.typeKgShort, ru: item.nameRu})
        }
      })
    },
  },
}
</script>
<style>
@import '../../../assets/styles/admin/create.css';
@import '../../../assets/styles/admin/navbar.css';
@import '../../../assets/styles/admin/form.css';
@import '../../../assets/styles/form.css';
</style>